<template>
  <div class="wap-refund">
    <headerBar :title="$t('tui-kuan-shen-qing')" @back="back"></headerBar>

    <div class="refund-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad"
      >
        <div class="list" v-for="(item, i) in dataList" :key="i">
          <div class="line flex-center-between">
            <div class="label">{{ $t("shen-qing-shi-jian") }}</div>
            <div class="value">{{ item.date }}</div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t("tui-kuan-dan-hao") }}</div>
            <div class="value">{{ item.orderNo }}</div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t("tui-kuan-jin-e") }}</div>
            <div class="value">${{ item.amount }}</div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t("tui-kuan-zhuang-tai") }}</div>
            <div class="value">{{ item.status }}</div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t("tui-kuan-li-you") }}</div>
            <div class="value">{{ item.reason }}</div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t("tui-kuan-shuo-ming-0") }}</div>
            <div class="value">{{ item.remark }}</div>
          </div>
        </div>
      </List>
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { List } from 'vant'
import { refundList } from '@/api/shop'
export default {
  components: {
    headerBar,
    List
  },
  data() {
    return {
      isInit: false,
      loading: false,
      finished: false,
      page: {
        page: 1,
        limit: 20
      },
      dataList: []
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    back() {
      this.$router.go(-1)
    },
    onLoad() {
      this.page.page += 1
      this.init(true)
    },
    init(isAdd) {
      // refundList({}).then((res) => {
      let res = {
        data: {
          Items: [
            // {
            //   orderNo: '1231231231232321',
            //   amount: 123,
            //   status: 1,
            //   remark: '备注111',
            //   reason: '理由111',
            //   date: '2024-10-31 12:33:44'
            // },
          ],
          Pagination: {
            totalPage: 1
          }
        }
      }
      console.log(111, res)
      if (res.data.Items) {
        let list = res.data.Items
        if (isAdd) {
          this.dataList = this.dataList.concat(list)
        } else {
          this.dataList = list
        }
        this.totalPage = res.data.Pagination.totalPage
        if (this.totalPage <= this.page.page) {
          this.finished = true
        }
      }
      this.isInit = true
      this.loading = false
      // })
    }
  }
}
</script>